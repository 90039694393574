.user-details{
    .details-container{
        .details-row{
            .details-col{
                margin: 10px 0px 10px 0px;
            }
            padding: 10px 10px 10px 10px;
            margin-top: 10px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            h4{
                margin: 20px 0px 10px 0px;
                padding-top: 10px;
                border-top: 1px solid #e0e0e0;
            }
            .change-button{
                background-color: #2b2b44;
                border: none;
                outline: none;
                margin: 10px 0px 10px 0px;
            }
        }
    }
}