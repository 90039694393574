.activity {
    .bg-blue-banner {
        background-color: #323232;
        height: 250px;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    .page-head {
        padding-top: 40px;
        padding-bottom: 40px;

        h1 {
            font-size: 30px;
            font-weight: 700;
            color: #fff;
        }

        p {
            margin-bottom: 0px;
            color: #888b9d;
            font-weight: 600;
        }

        .import-btn {
            display: flex;
            align-items: center;
            padding: 8px 25px;
            background-color: #fff;
            font-size: 14px;
            color: #323232;
            font-weight: 600;
            border: 0px solid #dbdfea;

            .btn-icon {
                margin-right: 10px;
            }
        }

        .add-btn {
            background-color: #fff;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0px solid transparent;
        }
    }

    .activities-list {
        .activities-list-body {
            .activity-card {
                height: 100%;
                border: 1px solid #dbdfea !important;

                .activity-cardbody {
                    h5 {
                        margin-bottom: 5px;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                        color: grey;
                        line-height: 18px;
                    }

                    .rsvp-list-button {
                        text-decoration: none;
                        font-size: 12px;
                        font-weight: 600;
                        background-color: #fff;
                        border: 2px solid #cd5828;
                        padding: 3px 8px;
                        color: #cd5828;
                        margin-bottom: 10px;
                    }

                    .rsvp-list-button:hover {
                        background-color: #cd5828;
                        color: #fff;
                    }

                    .custom-badge.offline {
                        width: max-content;
                        background-color: rgba($color: #cd5828, $alpha: 0.2);
                        padding: 3px 10px;
                        border-radius: 5px;
                        margin-bottom: 10px;

                        p {
                            font-size: 12px !important;
                            font-weight: 600;
                            margin-bottom: 0px;
                            color: #cd5828 !important;
                        }
                    }

                    .custom-badge.online {
                        width: max-content;
                        background-color: rgba($color: #323232, $alpha: 0.2);
                        padding: 3px 10px;
                        border-radius: 5px;
                        margin-bottom: 10px;

                        p {
                            font-size: 12px !important;
                            font-weight: 600;
                            margin-bottom: 0px;
                            color: #323232 !important;
                        }
                    }

                    .meeting-link {
                        margin-top: 15px;

                        .meeting-link-heading {
                            margin-top: 10px;
                            font-weight: 500;
                            color: grey;
                        }

                        a {
                            font-size: 14px;
                            text-decoration: none;
                            font-weight: 600;
                        }

                        .address {
                            color: #323232;
                            font-weight: 600;
                        }
                    }

                    .card-loading {
                        height: 150px;
                    }

                    .show-more-content-css {
                        margin-top: 10px;

                        span {
                            span {
                                font-size: 14px;
                                font-weight: 400;
                                color: #808080;
                                max-width: 100% !important;
                            }

                            .show-more-less-clickable {
                                color: #cd5828;
                                text-decoration: none;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .activity-cardfooter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #fff;
                    border-top: 1px solid #dbdfea;

                    .date-range {
                        span {
                            font-size: 14px;
                            color: grey;
                            font-weight: 500;
                        }

                        p {
                            font-size: 14px;
                            margin-bottom: 0px;
                            font-weight: 600;
                            color: #323232;
                        }
                    }

                    .card-action {
                        .dropdown-toggle {
                            background-color: #fff;
                            border-radius: 50%;
                            padding: 6px 10px;
                            border-color: #fff;
                        }

                        .dropdown-toggle:hover {
                            background-color: rgba($color: #323232, $alpha: 0.1);
                        }

                        .dropdown-toggle::after {
                            display: none;
                        }

                        .card-action-menu {
                            .menu-item {
                                font-size: 13px;
                                color: #323232;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                        }
                    }
                }

                .ac-footer {
                    background-color: #fff;
                    border-top: 1px solid #dbdfea;
                    height: 62px;

                    .card-loading {
                        height: 38px;
                    }
                }
            }
        }
    }
}
