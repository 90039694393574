.leads-dashboard {
  .bg-blue-banner {
    background-color: #323232;
    height: 250px;
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  .page-head {
    padding-top: 40px;
    padding-bottom: 40px;

    h1 {
      font-size: 30px;
      font-weight: 700;
      color: #fff;
    }

    p {
      margin-bottom: 0px;
      color: #888b9d;
      font-weight: 600;
    }
  }

  .settings-card {
    border: 0px solid transparent;

    .settings-card-body {
      padding: 0px;

      .organization-form {
        .form-group {
          label {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 5px;
          }

          .upload-logo {
            display: flex;
            align-items: center;

            .logo {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 60px;
              width: 60px;
              border-radius: 5px;
              border: 1px solid #e5e5e5;
              padding: 5px;

              p {
                color: #fff;
                font-weight: 700;
                margin-bottom: 0px;
              }

              img {
                width: 100%;
                height: auto;
              }
            }

            .logo-input {
              margin-left: 10px;
              font-size: 12px;
              padding: 5px;
              width: 300px;
            }
          }
        }
      }
    }
  }

  .settings-tabs {
    .settings-tabs-cardheader {
      background-color: #fff;

      .card-header-pills {
        .nav-link.active {
          font-size: 14px;
          font-weight: 600;
          background-color: #cd5828;
          color: #fff;
        }

        .nav-link {
          color: grey;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .settings-tabs-cardbody {
      padding: 20px;

      .left-content {
        .content-head {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .content-para {
          font-size: 14px;
          color: grey;
          font-weight: 400;
          margin-bottom: 0px;
        }

        .invite-btn {
          font-size: 14px;
          font-weight: 600;
          background-color: #cd5828;
          margin-top: 15px;
          border-color: #cd5828;
        }
      }

      .table-card {
        background-color: #f3f5f7;
        border: 0px solid transparent;

        .table-cardbody {
          .table {
            width: 100%;

            .table-head {
              th {
                color: #afafaf;
                font-size: 11px;
                font-weight: 500;
              }
            }

            .table-body {
              tr {
                border-bottom: 1px solid #e5e5e5;
                vertical-align: middle;

                td {
                  color: #636363;
                  font-weight: 500;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 13px;
                  padding-left: 0px;
                }

                .member-details {
                  display: flex;
                  align-items: center;

                  .permission {
                    color: #323232 !important;
                    font-size: 14px !important;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin-bottom: 0px;
                  }

                  .member-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 35px;
                    width: 35px;
                    background-color: #323232;
                    border-radius: 50%;

                    p {
                      margin-bottom: 0px;
                      color: #fff;
                    }
                  }

                  .member-info {
                    margin-left: 10px;

                    .name {
                      color: #323232 !important;
                      font-size: 14px !important;
                      font-weight: 600;
                      text-transform: capitalize;
                      margin-bottom: 0px;
                    }

                    .email {
                      color: #636363;
                      font-weight: 400;
                      font-size: 12px;
                      margin-bottom: 0px;
                    }
                  }

                  .key-info {
                    .name {
                      color: #323232 !important;
                      font-size: 14px !important;
                      font-weight: 600;
                      text-transform: capitalize;
                      margin-bottom: 0px;
                    }

                    .email {
                      color: #636363;
                      font-weight: 400;
                      font-size: 12px;
                      margin-bottom: 0px;
                    }
                  }
                }

                .role-size {
                  font-size: 12px;
                  padding: 5px 10px;
                  font-weight: 500;
                  text-transform: capitalize;
                  background-color: #cd5828 !important;
                }

                .role-select__control {
                  border: 0px solid transparent;
                  background-color: #323232;

                  .role-select__single-value {
                    color: #fff;
                  }

                  .role-select__indicator-separator {
                    display: none;
                  }
                }

                .delete-member-btn {
                  background-color: #f3f5f7;
                  border-radius: 50%;
                  padding: 5px 10px;
                  border-color: transparent;
                }

                .delete-member-btn:hover {
                  background-color: rgba($color: #000, $alpha: 0.1);
                }
              }

              .resend-invitation-btn {
                background-color: #323232;
                color: #fff;
                border-color: #000;
                font-size: 14px;
                font-weight: 600;
              }

              .revoke-invitation-btn {
                background-color: #f3f5f7;
                border: 1px solid #323232;
                margin-left: 10px;
                color: #000;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }

          .empty-list {
            text-align: center;
            margin-top: 10px;
          }

          .css-pdct74-MuiTablePagination-selectLabel {
            margin-bottom: 0rem;
          }

          .css-levciy-MuiTablePagination-displayedRows {
            margin-bottom: 0rem;
          }

          .pagination-footer {
            background-color: #f3f5f7;
            padding: 0px;
            border-top: 0px solid transparent;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .card-footer-left {
              span {
                font-size: 13px;
                font-weight: 600;
              }
            }

            .card-footer-right {
              .pagination {
                margin-bottom: 0px;

                .page-item {
                  .page-link {
                    color: #6c757d;
                    font-size: 13px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }

      .role-card {
        background-color: #f3f5f7;
        border: 0px solid transparent;
        margin-bottom: 15px;

        .role-cardbody {
          padding: 10px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h5 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0px;
            padding-right: 10px;
            word-wrap: break-word;
          }

          .delete-role {
            height: 30px;
            width: 30px;
            background-color: rgba($color: #000, $alpha: 0.1);
            border: 0px solid transparent;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
          }
        }
      }

      .checkbox-tick {
        label {
          justify-content: center !important;
        }
      }

      .new-checkbox-control {
        height: 16px !important;
        width: 16px !important;
        border-color: #cccccc !important;
        border-radius: 3px !important;
      }

      .expiration-settings-checkbox {
        width: 100%;
        background-color: #f3f5f7;
        padding: 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        .checkbox-control {
          height: 20px !important;
          width: 20px !important;
          border-color: #cccccc !important;
          border-radius: 3px !important;
        }

        .details {
          margin-left: 15px;

          h5 {
            margin-bottom: 0px;
            font-size: 15px;
            font-weight: 600;
          }

          p {
            margin-bottom: 0px;
            font-size: 13px;
            color: #636363;
          }
        }
      }

      .modules-card {
        margin-bottom: 10px;

        .modules-cardheader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          padding-top: 10px;
          padding-bottom: 10px;

          h5 {
            font-size: 14px;
            margin-bottom: 0px;
            font-weight: 600;
          }

          .methods-number {
            background-color: #f2faf5;
            font-size: 12px;
            font-weight: 600;
            border-radius: 20px;
            border: 1px solid #059450;
            padding: 3px 15px;
          }
        }

        .modules-cardbody {
          h5 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0px;
          }

          p {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 0px;
          }
        }
      }

      .general-settings {
        background-color: #f3f5f7;
        box-shadow: none;
        border: 0px solid transparent;

        .general-settings-header {
          background-color: #f3f5f7;
          border-bottom: 1px solid #e5e5e5;
          padding-top: 10px;
          padding-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h5 {
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 600;
          }

          .update-settings {
            background-color: #cd5828;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .general-settings-body {
          background-color: #f3f5f7;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      .activities-list {
        background-color: #f3f5f7;
        border: 0px solid transparent;
        box-shadow: none;

        .activities-list-header {
          background-color: #f3f5f7;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e5e5e5;

          h5 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
          }
        }

        .activities-list-body {
          padding: 15px;
        }
      }

      .global-settings-card {
        background-color: #f3f5f7;
        border: 0px solid transparent;
        box-shadow: none;

        .global-settings-cardheader {
          background-color: #f3f5f7;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h5 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
          }

          .update-settings {
            background-color: #cd5828;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
  
  .filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .filter-badge-primary {
      background-color: rgba($color: #cd5828, $alpha: 0.2);
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;

      p {
        font-size: 12px;
        font-weight: 600;
        color: #cd5828;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
    .filter-badge-secondary {
      background-color: rgba($color: #000, $alpha: 0.2);
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;

      p {
        font-size: 12px;
        font-weight: 600;
        color: #f2faf5;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
    .filter-badge-success {
      background-color: rgba($color: #80ab80, $alpha: 0.2);
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;

      p {
        font-size: 12px;
        font-weight: 600;
        color: #408944;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
    .filter-badge-info {
      background-color: rgba($color: #1aaef3, $alpha: 0.2);
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;

      p {
        font-size: 12px;
        font-weight: 600;
        color: #049ce2;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
    .filter-badge-error {
        background-color: rgba($color: #D31510, $alpha: 0.2);
        padding: 5px 10px;
        border-radius: 5px;
        margin-right: 10px;
  
        p {
          font-size: 12px;
          font-weight: 600;
          color: #D31510;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        }
      }
    .filter-badge-warning {
      background-color: rgba($color: #dda807, $alpha: 0.2);
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;

      p {
        font-size: 12px;
        font-weight: 600;
        color: #f9bd0b;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
  }
}

.status-dropdown{
  font-size: 13px !important;
  color: #8094AE !important;
  font-weight: 600;
  padding: 8px 15px !important;
  width: 150px !important;
}